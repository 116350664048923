import { FunctionComponent, memo, useCallback, useEffect, useMemo, useState } from "react";
import { Arrow, useHover, useLayer } from "react-laag";
import { AnimatePresence, motion } from "motion/react";
import { doNothing } from "@edgetier/utilities";
import { PopoverHeader } from "./components/popover-header";
import { PopoverFooter } from "./components/popover-footer";
import { PopoverContent } from "./components/popover-content";
import { IProps } from "./popover.types";
import PopoverProvider from "./components/popover-provider/popover-provider";
import "./popover.scss";

/**
 * Popover component renders a popover that can be opened and closed by clicking on a trigger element.
 *
 * @param children      Children component
 * @param isInitialOpen Popover open state on first render
 * @param open          Popover open state
 * @param headerTitle   Header title content
 * @param content       Content to be displayed in the popover
 * @param footer        Footer content
 * @param trigger       What triggers the open / close. Default or unknown value will be handled as "click". Can also be "hover".
 * @param isLockedOpen  If the popover is locked open, it will never close. Default to false.
 * @param onChange      Callback function to be called when the popover is opened or closed
 * @returns             Popover component
 */
const Popover: FunctionComponent<IProps> = ({
    children,
    isInitialOpen,
    open,
    headerTitle,
    content,
    footer,
    trigger,
    isLockedOpen: initialIsLockedOpen = false,
    onChange,
}) => {
    const [isOpenByClick, setIsOpenByClick] = useState(isInitialOpen || false);
    const [isLockedOpen, setIsLockedOpen] = useState(initialIsLockedOpen);
    const [isHovered, hoverProps] = useHover({ delayEnter: 100, delayLeave: 100 });

    useEffect(() => {
        if (typeof open !== "undefined") {
            setIsOpenByClick(open);
        }
    }, [open]);

    const close = useCallback(() => {
        if (!isLockedOpen) {
            onChange?.(false);
            setIsOpenByClick(false);
        }
    }, [onChange, isLockedOpen]);

    const openPopover = useCallback(() => {
        onChange?.(true);
        setIsOpenByClick(true);
    }, [onChange]);

    const toggleOpen = useCallback(() => {
        if (isOpenByClick) {
            close();
        } else {
            openPopover();
        }
    }, [isOpenByClick, close, openPopover]);

    const isOpen = useMemo(() => {
        return typeof open !== "undefined" ? open : (trigger === "hover" ? isHovered : isOpenByClick) || isLockedOpen;
    }, [open, trigger, isOpenByClick, isHovered, isLockedOpen]);

    const {
        renderLayer,
        triggerProps,
        layerProps: { style: layerStyles, ...otherLayerProps },
        arrowProps,
    } = useLayer({
        isOpen,
        onOutsideClick: close, // close the menu when the user clicks outside
        onDisappear: (disappearType) => {
            // close the menu when the menu gets scrolled out of sight
            if (isLockedOpen || disappearType !== "full") {
                doNothing();
            } else {
                close();
            }
        },
        overflowContainer: true, // keep the menu positioned inside the container
        auto: true, // automatically find the best placement
        placement: "left-start", // we prefer to place the menu "top-end"
        triggerOffset: 12, // keep some distance to the trigger
        containerOffset: 16, // give the menu some room to breath relative to the container
        arrowOffset: 16, // let the arrow have some room to breath also
    });

    return (
        <PopoverProvider
            context={{
                isOpen,
                isLockedOpen,
                close,
                lockOpen: () => setIsLockedOpen(true),
                unlock: () => setIsLockedOpen(false),
            }}
        >
            <div data-testid="popover-trigger" {...triggerProps} {...hoverProps} onClick={toggleOpen}>
                {children}
            </div>
            {renderLayer(
                <AnimatePresence>
                    {isOpen && (
                        <motion.div
                            style={{ originX: 1, originY: 0, ...layerStyles }}
                            initial={{ scale: 0.5, opacity: 0 }}
                            animate={{ scale: [0.5, 0.75, 1], opacity: [0, 1] }}
                            exit={{ scale: 0.5, opacity: 0 }}
                            data-testid="popover-container"
                            transition={{
                                duration: 0.1,
                            }}
                            className={`popover-container`}
                            {...otherLayerProps}
                        >
                            <PopoverHeader title={headerTitle} />
                            <PopoverContent>{content}</PopoverContent>
                            <PopoverFooter>{footer}</PopoverFooter>
                            <Arrow
                                onPointerEnterCapture={undefined}
                                onPointerLeaveCapture={undefined}
                                {...arrowProps}
                            />
                        </motion.div>
                    )}
                </AnimatePresence>
            )}
        </PopoverProvider>
    );
};

export default memo(Popover);
