import { IInteractionMetricsAvailable } from "./interactions";

export enum InteractionsAnalyticsXAxisGroupValues {
    TIME = "time",
    OTHER = "other",
}
export enum InteractionsAnalyticsXAxisValues {
    MINUTES = "MINUTE",
    HOURS = "HOUR",
    DAYS = "DAY",
    WEEKS = "WEEK",
    MONTHS = "MONTH",
    LANGUAGES = "LANGUAGE",
    AGENTS = "AGENT",
}

export interface IMetric {
    key: string;
    dataKey: string;
    group: string | null;
    visibleText: string;
    type: "count" | "average";
}

export enum SplitBy {
    AGENT = "agent",
    LANGUAGE = "language",
    INTERACTION_TYPE = "interactionType",
}

export interface IInteractionsAnalyticsGetQueryParameters {
    readonly groupBy: InteractionsAnalyticsXAxisValues;
    readonly measurement: string;
    readonly splitBy: SplitBy;
    readonly startDateTime?: Date;
    readonly endDateTime?: Date;
}

export type IInteractionsAnalyticsResponse = Array<{ [key: string]: number }>;

export interface IInteractionsAnalyticsMetricsGetQueryParameters {}
export type IInteractionAnalyticsMetricsResponse = IInteractionMetricsAvailable;
